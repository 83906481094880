const doc = {
	templates: [
		{
			id: 'template-1',
			name: 'Bienvenue chez nous !',
			status: 'toAssign',
			lastUpdate: '2022-06-01',
			tags: ['Tout', 'Junior'],
			users: ['a', 'b', 'c'],
			teamName: 'Sales',
			progress: 45,
			docs: ['doc id'],
		},
		{
			id: 'template-2',
			name: "Découvrir l'équipe",
			status: 'toAssign',
			lastUpdate: '2022-06-01',
			tags: ['Tout', 'Junior'],
			users: ['a', 'b', 'c'],
			teamName: 'Sales',
			progress: 20,
			docs: ['doc id'],
		},
		{
			id: 'template-3',
			name: 'La vie chez Frichti ',
			status: 'toAssign',
			lastUpdate: '2022-06-01',
			tags: ['Tout', 'Junior'],
			users: ['a', 'b', 'c'],
			teamName: 'Sales',
			progress: 100,
			docs: ['doc id'],
		},
		{
			id: 'template-4',
			name: 'La vente chez Frichti',
			status: 'toAssign',
			lastUpdate: '2022-06-01',
			tags: ['Tout', 'Junior'],
			users: ['a', 'b', 'c'],
			teamName: 'Sales',
			progress: 0,
			docs: ['doc id'],
		},
		{
			id: 'template-5',
			name: "Les missions de l'account Executive",
			status: 'toAssign',
			lastUpdate: '2022-06-01',
			tags: ['Tout', 'Junior'],
			users: ['a', 'b', 'c'],
			teamName: 'Sales',
			progress: 30,
			docs: ['doc id'],
		},
		{
			id: 'template-6',
			name: 'Les comptes et outils à configurer',
			status: 'toAssign',
			lastUpdate: '2022-06-01',
			tags: ['Tout', 'Junior'],
			users: ['a', 'b', 'c'],
			teamName: 'Sales',
			progress: 90,
			docs: ['doc id'],
		},
		{
			id: 'template-7',
			name: 'Les outils pour les sales',
			status: 'toAssign',
			lastUpdate: '2022-06-01',
			tags: ['Tout', 'Junior'],
			users: ['a', 'b', 'c'],
			teamName: 'Sales',
			progress: 40,
			docs: ['doc id'],
		},
	],
	documents: [
		{
			name: 'Contrat-de-travail.pdf',
			uploadName: 'contrat de travail',
			uploadDate: '2022-06-22',
			teamName: 'Ressources Humaines',
			templateName: 'Arrivé',
			date: '2022-04-22',
			status: 'toLate',
			id: 'azeaze',
		},
		{
			name: 'charte-informatique.pdf',
			uploadName: 'Charte informatique',
			uploadDate: '2022-06-22',
			teamName: 'Ressources Humaines',
			templateName: 'Arrivé',
			date: '2022-04-22',
			status: 'good',
			id: 'azeaze',
		},
	],
};

export const MY_TEAM = {
	name: 'Sales',
	id: 'auzgeiugaze',
	members: [
		{
			firstName: 'Némo',
			lastName: 'F',
			profilePic: '/assets/test/nemo.png',
			email: 'nemo.f@frichti.co',
			role: 'Sales apprantice',
			id: 'mate-3',
			lastUpdate: '2022-06-18',
			createdDate: '2022-06-15',
			lastActivity: '2022-06-25',
			userPerm: 'onboardee',
			...doc,
		},
		{
			firstName: 'Noé',
			lastName: 'C',
			profilePic: '/assets/test/Noe.png',
			email: 'noe.c@frichti.co',
			role: 'Senior Sales Manager',
			id: 'mate-1',
			lastUpdate: '2022-06-18',
			createdDate: '2022-06-15',
			lastActivity: '2022-06-30',
			userPerm: 'editor',
			...doc,
		},
		{
			firstName: 'Clément',
			lastName: 'P',
			profilePic: '/assets/test/clement.png',
			email: 'clement.p@frichti.co',
			role: 'Senior Sales ',
			id: 'mate-2',
			lastUpdate: '2022-06-18',
			createdDate: '2022-06-15',
			lastActivity: '2022-06-25',
			userPerm: 'editor',
			...doc,
		},
		{
			firstName: 'Constance',
			lastName: 'D',
			profilePic: '/assets/test/constance.png',
			email: 'constance.d@frichti.co',
			role: 'Senior Sales',
			id: 'mate-4',
			lastUpdate: '2022-06-18',
			createdDate: '2022-06-15',
			lastActivity: '2022-06-25',
			userPerm: 'onboardee',
			...doc,
		},
		{
			firstName: 'Théa',
			lastName: 'D',
			profilePic: '/assets/test/thea.png',
			email: 'theadosto@frichti.co',
			role: 'Sales apprentice',
			id: 'mate-5',
			lastUpdate: '2022-07-01',
			createdDate: '2022-06-15',
			lastActivity: '2022-06-25',
			userPerm: 'onboardee',
			...doc,
		},
		{
			firstName: 'Alban',
			lastName: 'D',
			profilePic: '/assets/test/alban.png',
			email: 'alban.d@frichti.co',
			role: 'Lead Senior Sales Manager',
			id: 'mate-6',
			lastUpdate: '2022-07-01',
			createdDate: '2022-06-15',
			lastActivity: '2022-06-25',
			userPerm: 'onboardee',
			...doc,
		},
		{
			firstName: 'Gaetan',
			lastName: 'A',
			profilePic: '/assets/test/gaetan.png',
			email: 'gaetan.a@frichti.co',
			role: 'Sales apprentice',
			id: 'mate-7',
			lastUpdate: '2022-07-01',
			createdDate: '2022-06-15',
			lastActivity: '2022-06-25',
			userPerm: 'onboardee',
			...doc,
		},
	],
};
