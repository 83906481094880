import {TEMPALTES} from './templates';
export const COMPANY_INFORMATION = {
	compagnie: {
		name: 'Frichti',
		logo: '/assets/test/logo.svg',
		teams: [
			{
				id: 'team-1',
				name: 'Finance',
				users: ['id1sd', 'idsdqs1', 'idx1'],
				lastUpdate: '2022-06-18',
				isFav: false,
				templates: [...TEMPALTES],
			},
			{
				id: 'team-2',
				name: 'Ressources humaines',
				users: ['id1sd', 'idsdqs1', 'idx1'],
				lastUpdate: '2022-06-18',
				isFav: false,
				templates: [...TEMPALTES],
			},
			{
				id: 'team-3',
				name: 'Marketing',
				users: ['id1sd', 'idsdqs1', 'idx1'],
				lastUpdate: '2022-06-18',
				isFav: false,
				templates: [...TEMPALTES],
			},
			{
				id: 'team-4',
				name: 'Opération',
				users: ['id1sd', 'idsdqs1', 'idx1'],
				lastUpdate: '2022-06-18',
				isFav: false,
				templates: [],
			},
			{
				id: 'team-5',
				name: 'Supply',
				users: ['id1sd', 'idsdqs1', 'idx1'],
				lastUpdate: '2022-06-18',
				isFav: false,
				templates: [],
			},
			{
				id: 'team-6',
				name: 'Sales',
				users: ['mate-1', 'mate-2', 'mate-3', 'mate-4', 'mate-5','mate-6','mate-7'],
				lastUpdate: '2022-06-18',
				isFav: false,
				templates: [...TEMPALTES],
			},
			{
				id: 'team-7',
				name: 'R&D',
				users: ['id1sd', 'idsdqs1', 'idx1'],
				lastUpdate: '2022-06-18',
				isFav: false,
				templates: [...TEMPALTES],
			},
			{
				id: 'team-8',
				name: 'Produit',
				users: ['id1sd', 'idsdqs1', 'idx1'],
				lastUpdate: '2022-06-18',
				isFav: false,
				templates: [...TEMPALTES],
			},
			{
				id: 'team-9',
				name: 'Service après vente',
				users: ['id1sd', 'idsdqs1', 'idx1'],
				lastUpdate: '2022-06-18',
				isFav: false,
				templates: [],
			},
			{
				id: 'team-10',
				name: 'IT',
				users: ['id1sd', 'idsdqs1', 'idx1'],
				lastUpdate: '2022-06-18',
				isFav: false,
				templates: [],
			},
		],
	},
};
