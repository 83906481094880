import { render, staticRenderFns } from "./Step-3.vue?vue&type=template&id=46707024&scoped=true&"
import script from "./Step-3.vue?vue&type=script&lang=js&"
export * from "./Step-3.vue?vue&type=script&lang=js&"
import style0 from "./Step-3.vue?vue&type=style&index=0&id=46707024&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46707024",
  null
  
)

export default component.exports