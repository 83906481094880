import {TEMPALTES} from './templates';
export const ADMIN = {
	jwt: 'admin',
	me: {
		firstName: 'Alban',
		lastName: 'D',
		profilePic: '/assets/test/alban.png',
		email: 'alban.d@frichti.co',
		role: 'Head of Product',
		userPerm: 'admin',
		lastActivity: '2022-06-20',
		createdDate: '2022-03-02',
		progress: 50,
		id: 'jm-1',
		templates: [
			{
				id: 'template-1',
				name: 'Bienvenue chez nous !',
				status: 'toAssign',
				lastUpdate: '2022-06-01',
				tags: ['Tout', 'Junior'],
				users: ['a', 'b', 'c'],
				teamName: 'Sales',
				progress: 45,
				docs: ['doc id'],
			},
			{
				id: 'template-2',
				name: "Découvrir l'équipe",
				status: 'toAssign',
				lastUpdate: '2022-06-01',
				tags: ['Tout', 'Junior'],
				users: ['a', 'b', 'c'],
				teamName: 'Sales',
				progress: 45,
				docs: ['doc id'],
			},
			{
				id: 'template-3',
				name: 'La vie chez Frichti ',
				status: 'toAssign',
				lastUpdate: '2022-06-01',
				tags: ['Tout', 'Junior'],
				users: ['a', 'b', 'c'],
				teamName: 'Sales',
				progress: 45,
				docs: ['doc id'],
			},
			{
				id: 'template-4',
				name: 'La vente chez Frichti',
				status: 'toAssign',
				lastUpdate: '2022-06-01',
				tags: ['Tout', 'Junior'],
				users: ['a', 'b', 'c'],
				teamName: 'Sales',
				progress: 45,
				docs: ['doc id'],
			},
			{
				id: 'template-5',
				name: "Les missions de l'account Executive",
				status: 'toAssign',
				lastUpdate: '2022-06-01',
				tags: ['Tout', 'Junior'],
				users: ['a', 'b', 'c'],
				teamName: 'Sales',
				progress: 45,
				docs: ['doc id'],
			},
			{
				id: 'template-6',
				name: 'Les comptes et outils à configurer',
				status: 'toAssign',
				lastUpdate: '2022-06-01',
				tags: ['Tout', 'Junior'],
				users: ['a', 'b', 'c'],
				teamName: 'Sales',
				progress: 45,
				docs: ['doc id'],
			},
			{
				id: 'template-7',
				name: 'Les outils pour les sales',
				status: 'toAssign',
				lastUpdate: '2022-06-01',
				tags: ['Tout', 'Junior'],
				users: ['a', 'b', 'c'],
				teamName: 'Sales',
				progress: 45,
				docs: ['doc id'],
			},
		],
		documents: [
			{
				name: 'Contrat-de-travail.pdf',
				uploadName: 'contrat de travail',
				uploadDate: '2022-06-22',
				teamName: 'Resource Humaine',
				templateName: 'Arriver',
				date: '2022-04-22',
				status: 'toLate',
				id: 'azeaze',
			},
			{
				name: 'charte-informatique.pdf',
				uploadName: 'Charte informatique',
				uploadDate: '2022-06-22',
				teamName: 'Resource Humaine',
				templateName: 'Arriver',
				date: '2022-04-22',
				status: 'good',
				id: 'azeaze',
			},
		],
		settings: {
			favs: [],
		},
	},
};

export const ONBOARDEE = {
	jwt: 'onboardee',
	me: {
		firstName: 'Némo',
		lastName: 'F',
		profilePic: '/assets/test/nemo.png',
		email: 'nemo.f@frichti.co',
		role: 'Sales apprantice',
		userPerm: 'onboardee',
		lastActivity: '2022-06-20',
		createdDate: '2022-03-02',
		progress: 30,
		id: 'ga-1',
		templates: [
			{
				id: 'template-1',
				name: 'Bienvenue chez nous !',
				status: 'toAssign',
				lastUpdate: '2022-06-01',
				tags: ['Tout', 'Junior'],
				users: ['a', 'b', 'c'],
				teamName: 'Sales',
				progress: 45,
				docs: ['doc id'],
			},
			{
				id: 'template-2',
				name: "Découvrir l'équipe",
				status: 'toAssign',
				lastUpdate: '2022-06-01',
				tags: ['Tout', 'Junior'],
				users: ['a', 'b', 'c'],
				teamName: 'Sales',
				progress: 20,
				docs: ['doc id'],
			},
			{
				id: 'template-3',
				name: 'La vie chez Frichti ',
				status: 'toAssign',
				lastUpdate: '2022-06-01',
				tags: ['Tout', 'Junior'],
				users: ['a', 'b', 'c'],
				teamName: 'Sales',
				progress: 100,
				docs: ['doc id'],
			},
			{
				id: 'template-4',
				name: 'La vente chez Frichti',
				status: 'toAssign',
				lastUpdate: '2022-06-01',
				tags: ['Tout', 'Junior'],
				users: ['a', 'b', 'c'],
				teamName: 'Sales',
				progress: 0,
				docs: ['doc id'],
			},
			{
				id: 'template-5',
				name: "Les missions de l'account Executive",
				status: 'toAssign',
				lastUpdate: '2022-06-01',
				tags: ['Tout', 'Junior'],
				users: ['a', 'b', 'c'],
				teamName: 'Sales',
				progress: 30,
				docs: ['doc id'],
			},
			{
				id: 'template-6',
				name: 'Les comptes et outils à configurer',
				status: 'toAssign',
				lastUpdate: '2022-06-01',
				tags: ['Tout', 'Junior'],
				users: ['a', 'b', 'c'],
				teamName: 'Sales',
				progress: 90,
				docs: ['doc id'],
			},
			{
				id: 'template-7',
				name: 'Les outils pour les sales',
				status: 'toAssign',
				lastUpdate: '2022-06-01',
				tags: ['Tout', 'Junior'],
				users: ['a', 'b', 'c'],
				teamName: 'Sales',
				progress: 40,
				docs: ['doc id'],
			},
		],
		documents: [
			{
				name: 'Contrat-de-travail.pdf',
				uploadName: 'contrat de travail',
				uploadDate: '2022-06-22',
				teamName: 'Resource Humaine',
				templateName: 'Arriver',
				date: '2022-04-22',
				status: 'toLate',
				id: 'azeaze',
			},
			{
				name: 'charte-informatique.pdf',
				uploadName: 'Charte informatique',
				uploadDate: '2022-06-22',
				teamName: 'Resource Humaine',
				templateName: 'Arriver',
				date: '2022-04-22',
				status: 'good',
				id: 'azeaze',
			},
		],
		settings: {
			favs: [],
		},
	},
};
